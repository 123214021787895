import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { FileCacheModel } from '@core';
import { DecimalPipe } from '@angular/common';
import { LoadingComponent } from '../loading/loading.component';
import { IProducts } from '@app/models';
import { FileCacheRepository } from '@core/lib/repositories/file-cache.repository';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { of, switchMap } from 'rxjs';

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  imports: [
    LoadingComponent,
    DecimalPipe,
  ],
  styleUrls: ['./product-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPreviewComponent {

  public readonly loading = signal(true);
  public readonly hesImagError = signal(false);

  public readonly product = input.required<IProducts>();
  public readonly open = output<void>();

  private readonly product$ = toObservable(this.product);

  private readonly productImage$ = this.product$.pipe(
    switchMap((product) => {
      if (typeof product.sku !== 'string') {
        return of(undefined);
      }

      return this.filesCacheRepository.one$(product.sku).pipe(
        map((file) => {
          if (file) {
            const img = new FileCacheModel(file);

            return img
              ? this.domSanitizer.bypassSecurityTrustUrl(img.objectUrl)
              : undefined;
          }

          return undefined;
        }),
      );
    }),
  );

  public readonly productImage = toSignal(this.productImage$);

  constructor(
    private readonly filesCacheRepository: FileCacheRepository,
    private readonly domSanitizer: DomSanitizer,
  ) {}

  onImageError($event: ErrorEvent): void {
    if ($event) {
      this.hesImagError.set(true);
    }
  }

  onLoad(): void {
    this.loading.set(false);
  }

  emitOpen(event: Event): void {
    event.preventDefault();

    const product = this.product();

    if (!product.volume || product.volume.length === 0) {
      this.open.emit();
    }
  }

}
