import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ISectionData } from '../kelseys-sections-grid/kelseys-sections-grid.component';
import { NgStyle } from '@angular/common';
import { UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-kelseys-more-section',
  templateUrl: './kelseys-more-section.component.html',
  imports: [
    NgStyle,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./kelseys-more-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KelseysMoreSectionComponent {

  public readonly back = output();

  public readonly data = input.required<ISectionData>();

}
