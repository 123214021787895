import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN, DBConfig, INDEXED_DB, NgxIndexedDBService } from 'ngx-indexed-db';
import { Repository } from '@core/lib/database/repository';
import { ISubCategories } from '@app/models';
import { appDatabase } from '@app/app.database';

@Injectable({
  providedIn: 'root',
})
export class SubCategoriesRepository extends Repository<ISubCategories, 'id'> {

  constructor(
    @Inject(CONFIG_TOKEN) dbConfigs: Record<string, DBConfig>,
    @Inject(INDEXED_DB) factory: IDBFactory,
  ) {
    const db = new NgxIndexedDBService(dbConfigs, factory);
    db.selectDb(appDatabase.name);

    super(db, 'subCategories', 'id');
  }

}
