@let isMenuPage = pageIs().menu;
@let isHomePage = pageIs().home;
@let isSpecialPage = pageIs().special;
@let isServicePage = pageIs().service;

<div
  class="tab-bar tab-bar__with-bg"
  [class.hide]="hideTabBar()"
>
  <div class="tabs" coreActivitySection="TabBar">
    <button
      class="tab"
      [class.underline]="isMenuPage"
      [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
      [coreUserActivityClickTracking] = "isMenuPage ? 'MenuClose' : 'MenuOpen'"
    >
      <div class="box">
        <div class="icon">
          <img src="/images/tabbar_menu.svg" alt=""/>
        </div>
        <div class="label">
          <span>{{ 'tabBar.menu' | translate }}</span>
        </div>
      </div>
    </button>

    <div class="divider"></div>

    <button
      class="tab logo"
      [class.underline]="isServicePage"
      [routerLink]="isHomePage ? '/main/home/service' : '/main/home'"
      [coreUserActivityClickTracking]="isHomePage ? 'ServicePageOpen' : 'PageClose'"
    >
      <div class="box">
        <div class="icon">
          <img class="logoImg" src="/images/logo.svg" alt=""/>
        </div>
        <div class="label">
          <span>{{ 'tabBar.dashboard' | translate }}</span>
        </div>
      </div>
    </button>

    <div class="divider"></div>

    <button
      class="tab"
      [class.underline]="isSpecialPage"
      [routerLink]="isSpecialPage ? '/main/home' : '/main/special'"
      [coreUserActivityClickTracking] = "isSpecialPage ? 'SpecialPageClose' : 'SpecialPageOpen'"
    >
      <div class="box">
        <div class="icon">
          <img src="/images/special.svg" alt=""/>
        </div>
        <div class="label">
          <span>{{ 'tabBar.special' | translate }}</span>
        </div>
      </div>
    </button>
  </div>
</div>

@if (hideTabBar()) {
  <core-interacts-present/>
}
