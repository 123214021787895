import { ChangeDetectionStrategy, Component, ElementRef, input, output, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { FileCacheModel, UserActivityClickTrackingDirective } from '@core';
import { IProducts } from '@app/models';
import { LoadingComponent } from '@app/components/loading/loading.component';
import { NewMenuApiService } from '@app/services/new-menu-api.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { of, switchMap } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { FileCacheRepository } from '@core/lib/repositories/file-cache.repository';

const nutritionDefault: {
  servingSize: string | null,
  calories: string | null,
  cholesterol: string | null,
  fat: string | null,
  transFat: string | null,
  saturatedFat: string | null,
  sodium: string | null,
  carbohydrates: string | null,
  fiber: string | null,
  sugar: string | null,
  protein: string | null,
  calcium: string | null,
  potassium: string | null,
  iron: string | null,
} = {
  servingSize: null,
  calories: null,
  cholesterol: null,
  fat: null,
  transFat: null,
  saturatedFat: null,
  sodium: null,
  carbohydrates: null,
  fiber: null,
  sugar: null,
  protein: null,
  calcium: null,
  potassium: null,
  iron: null,
};

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  imports: [
    UserActivityClickTrackingDirective,
    LoadingComponent,
    DecimalPipe,
  ],
  styleUrls: ['./product-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsComponent {

  public readonly close = output<void>();
  public readonly product = input.required<IProducts>();

  private readonly product$ = toObservable(this.product);

  private readonly productImage$ = this.product$.pipe(
    switchMap((product) => {
      if (typeof product.sku !== 'string') {
        return of(undefined);
      }

      return this.filesCacheRepository.one$(product.sku).pipe(
        map((file) => {
          if (file) {
            const img = new FileCacheModel(file);

            return img
              ? this.domSanitizer.bypassSecurityTrustUrl(img.objectUrl)
              : undefined;
          }

          return undefined;
        }),
      );
    }),
  );

  private readonly nutrition$ = this.product$.pipe(
    switchMap((product) => {
      if (!product.name) {
        return of(nutritionDefault);
      }

      return this.api.nutrition$.pipe(
        map((items) => items.find((nutrition) => {
          if (typeof nutrition[9] !== 'string') {
            return false;
          }

          const productName = product.name.toLowerCase();
          return nutrition[9].toLowerCase().startsWith(productName);
        })),
        map((nutrition) => {
          if (!nutrition) {
            return nutritionDefault;
          }

          return {
            servingSize: nutrition[10],
            calories: nutrition[11],
            fat: nutrition[12],
            saturatedFat: nutrition[13],
            transFat: nutrition[14],
            carbohydrates: nutrition[15],
            fiber: nutrition[16],
            sugar: nutrition[17],
            protein: nutrition[18],
            cholesterol: nutrition[19],
            sodium: nutrition[20],
            potassium: nutrition[21],
            calcium: nutrition[22],
            iron: nutrition[23],
          };
        }),
      );
    }),
  );

  public readonly allergens$ = this.product$.pipe(
    map((product) => product.allergenList?.map((v) => v.trim()).join(', ') || ''),
  );

  public readonly productImage = toSignal(this.productImage$);
  public readonly nutrition = toSignal(this.nutrition$);
  public readonly allergens = toSignal(this.allergens$);

  public readonly accordion = signal<boolean>(false);
  public readonly loading = signal<boolean>(true);
  public readonly hesImagError = signal<boolean>(false);

  constructor(
    private readonly element: ElementRef,
    private readonly filesCacheRepository: FileCacheRepository,
    private readonly domSanitizer: DomSanitizer,
    private readonly api: NewMenuApiService,
  ) {}

  toggleAccordion(): void {
    this.accordion.set(!this.accordion());

    requestAnimationFrame(() => {
      if (this.accordion()) {
        this.element.nativeElement.scrollTo({
          top: this.element.nativeElement.scrollHeight,
          behavior: 'smooth',
        });

        return;
      }

      this.element.nativeElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }

  onImageError($event: ErrorEvent): void {
    if ($event) {
      this.hesImagError.set(true);
    }
  }

  onLoad(): void {
    this.loading.set(false);
  }

}
