<div class="box-wrapper">
  <div class="table-name">{{ 'serviceCenter.table' | translate }} {{ table()?.tableName }}</div>

  @if (withCallWaiterMain()) {
    <button
      class="call-waiter-btn"
      (click)="handlerCallWaiter($event)"
      [class.active]="callWaiterMain()"
      coreActivitySection="CallWaiter"
      [coreUserActivityClickTracking]="callWaiterMain() ? 'CancelCallWaiter' : 'CallWaiter'"
    >
      <div class="bell">
        <svg width="32" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M25.535 18.82v-4.926c0-5.37-4.34-9.74-9.674-9.74s-9.673 4.37-9.673 9.74v4.927c0 2.768-1.014 5.43-2.788 7.487h24.923a11.492 11.492 0 0 1-2.788-7.487ZM13.092 33.269c0 1.506 1.242 2.731 2.77 2.731 1.527 0 2.769-1.225 2.769-2.731v-.038h-5.539v.038ZM13.092 2.731c0-1.506 1.242-2.731 2.77-2.731 1.527 0 2.769 1.225 2.769 2.731v.038h-5.539v-.038ZM2.016 27.692v4.154h27.692v-4.154H2.016ZM28.173 6.781a13.678 13.678 0 0 0-1.543-2.016 1.279 1.279 0 0 0-1.69-.17 1.44 1.44 0 0 0-.4 1.928c.044.07.095.134.152.194 2.48 2.678 3.523 6.487 2.779 10.184l-.114.566c-.153.758.307 1.488 1.03 1.634.723.145 1.43-.35 1.583-1.108l.114-.565c.75-3.697.065-7.53-1.91-10.647Z"
            fill="currentColor"/>
          <path
            d="m4.793 17.495-.122-.564c-.79-3.68.195-7.498 2.644-10.2.502-.553.469-1.44-.07-1.975a1.306 1.306 0 0 0-.196-.158 1.28 1.28 0 0 0-1.688.192 13.36 13.36 0 0 0-1.52 2.035c-1.936 3.14-2.574 6.981-1.776 10.672l.122.564c.162.756.875 1.241 1.597 1.086.72-.155 1.17-.896 1.009-1.652Z"
            fill="currentColor"/>
        </svg>
      </div>

      <div class="content" [class.active]="!callWaiterMain()">
        <span>{{ 'serviceCenter.callWaiter' | translate }}</span>
      </div>

      <div class="content small" [class.active]="callWaiterMain()">
        <span>{{ 'serviceCenter.callWaiterCancel' | translate }}</span>
      </div>
    </button>
  }

  <app-kelseys-sections-grid coreActivitySection="SectionsGrid"/>

  <div class="box-content">

    @if (table()?.wifiName; as wifiName) {
      <div class="box-content__wrapper">
        <div class="icon">
          <img src="/images/wifi.svg" alt=""/>
        </div>
        <div class="divider"></div>
      <div class="info">
          <div class="network">
            <span class="network__title"> {{ 'serviceCenter.wifi.name' | translate }}</span>
            <span class="network__value">{{ wifiName }}</span>
          </div>

        @if (table()?.wifiPassword; as wifiPassword) {
            <div class="network">
              <span class="network__title"> {{ 'serviceCenter.wifi.pass' | translate }}</span>
              <span class="network__value"> {{ wifiPassword }} </span>
            </div>
          }
        </div>
      </div>
    }
    <div class="box-content__wrapper">
      <div class="icon">
        <img src="/images/battery.svg" alt=""/>
      </div>
      <div class="divider"></div>
      <div class="info">
        <div class="charge">
          {{ 'serviceCenter.chargeDevice.title' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
