@if (!isSolo()) {
  <button
    class="header"
    type="button"
    [class.stuck]="headerSticky()"
    (click)="close.emit()"
    coreUserActivityClickTracking="CloseCategory"
  >
    <div class="back-navigation">
      <img src="/images/back.svg" alt="">
    </div>
    <div class="category-name">{{ category().categoryName }}</div>
  </button>
}

<div class="sub_categories" [class.solo]="isSolo()">
  @for (subCategory of subCategories(); track subCategory.id) {
    <div class="sub_category">
      @if (subCategory.categoryName && subCategory.categoryName.length > 0) {
        <h3>{{ subCategory.categoryName }}</h3>
      }

      <div class="sub_category__products">
        @for (product of subCategory.products; track product.id) {
          <app-product-preview
            class="product"
            [product]="product"
            (open)="openProduct.emit(product)"
          />
        }
      </div>
    </div>
  }
</div>
