@let withImage = !hesImagError() && product().sku !== 0 && productImage();
@let calories = product().calories;
@let volume = product().volume;

<div
  class="product__preview"
  (click)="emitOpen($event)"
>
  @if (withImage) {
    <div class="product__preview__img" [class.loading]="loading()">
      @if (loading()) {
        <app-loading/>
      }

      <img [src]="productImage()" alt="" (load)="onLoad()" (error)="onImageError($event)">
    </div>
  }

  <div class="product__preview__info" [class.no__img]="!withImage">
    <div class="name">{{ product().name }}</div>
    @if (volume && volume.length > 0) {
      @if (calories && calories.length > 0) {
        <div class="calories">
          {{ calories }}
        </div>
      }
      <div class="variants">
        @for (item of volume; track item) {
          <div class="variant">
            <div class="variant__volume">{{ item }}</div>
          </div>
        }
      </div>

      <div class="footer">
        <div class="price">
          @if (volume.length > 1) {
            from
          }
          {{ product().productPrice | number:'1.2-3' }}
        </div>
      </div>
    } @else {
      <div class="description">{{ product().description }}</div>
      @if (calories && calories.length > 0) {
        <div class="calories">
          {{ calories }}
        </div>
      }
      <div class="footer">
        <div class="price">{{ product().productPrice | number:'1.2-3' }}</div>
        <button class="details">
          <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 22.008v-6.763h-5.072M10.928 22.008H21.07M14.31 8.483h3.38" stroke="#fff" stroke-width="2"
                  stroke-miterlimit="10"/>
            <path d="M16 30.5C8.005 30.5 1.5 23.995 1.5 16S8.005 1.5 16 1.5 30.5 8.005 30.5 16 23.995 30.5 16 30.5Z"
                  stroke="#fff" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </button>
      </div>
    }
  </div>
</div>
