@if (confetti) {
  <app-confetti></app-confetti>
}


<div [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<core-background-player
  style="
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: transparent;
"
></core-background-player>

<app-tab-bar></app-tab-bar>
