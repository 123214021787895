@if (isOnline() && gCategories().length > 0) {
  <div
    class="g_categories"
    coreActivitySection="GlobalCategories"
  >
    @for (gCategory of gCategories(); track gCategory.hierarchyId) {
      <button
        @fadeIn
        class="g_category"
        [class.active]=isActiveSection(gCategory.hierarchyId)
        (click)="setActive(gCategory.hierarchyId)"
        [coreUserActivityClickTracking]="gCategory.name"
      >
        @if (gCategory.icon) {
          <img class="icon" [src]="gCategory.icon" alt="">
        } @else {
          <img class="icon" src="/images/tabbar_menu.svg" alt="">
        }
        <span class="name" [class.active]=isActiveSection(gCategory.hierarchyId)>
          {{ gCategory.name }}
        </span>
      </button>
    }
  </div>
}

@if (isLoading() && isOnline() && gCategories().length > 0) {
  <app-loading/>
} @else {
  <div coreActivitySection="Categories">
    @if (isOnline()) {
      @if (gCategories().length === 0) {
        <div class="noConnect">
          <img class="noConnect__icon" src="/images/tabbar_menu.svg" alt="No menu">
          <div class="noConnect__text">
            The menu has not been added yet
          </div>
        </div>
      } @else {
        @for (category of categories; track category.id) {
          <div
            @fadeIn
            class="category"
            (click)="currentCategory = category"
            [coreUserActivityClickTracking]="category.categoryName"
          >
            <img
              class="category__bg"
              [src]="category.sanitizedUrl ? category.sanitizedUrl : getDefaultBg(category)"
              alt=""
            />
            <div class="category__name">
              <span>{{ category.categoryName }}</span>
            </div>
          </div>
        }
      }
    } @else {
      <div class="noConnect">
        <img class="noConnect__icon" src="/images/wifi.svg" alt="No Connect">
        <div class="noConnect__text">
          No internet connection
        </div>
      </div>
    }

  @if (this.currentCategory) {
    @if (this.categories.length > 1) {
      <app-menu-category
        @slideInOut
        [isSolo]="false"
        [category]="this.currentCategory"
        [coreActivitySection]="'Category'+this.currentCategory.categoryName"
        (close)="this.currentCategory = null"
        (openProduct)="this.currentProduct = $event"
      />
    } @else {
      <app-menu-category
        @fadeIn
        [style.margin-top.px]="120"
        [isSolo]="true"
        [category]="this.currentCategory"
        [coreActivitySection]="'Category'+this.currentCategory.categoryName"
        (close)="this.currentCategory = null"
        (openProduct)="this.currentProduct = $event"
      />
    }
  }

  @if (this.currentProduct) {
    <app-product-details
      @slideInOut
      [product]="this.currentProduct"
      [coreActivitySection]="'Product'+this.currentProduct.name"
      (close)="this.currentProduct = null"
    />
  }
  </div>
}
