import { Component, ElementRef, HostListener, input, output, signal } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { ProductPreviewComponent } from '../product-preview/product-preview.component';
import { IMenuCategory, IProducts, ISubCategories } from '@app/models';
import { UserActivityClickTrackingDirective } from '@core';
import { ProductsRepository } from '@app/repositories/products.repository';
import { SubCategoriesRepository } from '@app/repositories/subCategories.repository';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  imports: [
    ProductPreviewComponent,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./menu-category.component.scss'],
})
export class MenuCategoryComponent {

  public readonly category = input.required<IMenuCategory>();
  public readonly isSolo = input.required<boolean>();

  public readonly close = output();
  public readonly openProduct = output<IProducts>();

  public readonly headerSticky = signal<boolean>(false);

  public readonly subCategories = toSignal(this.subCategoriesRepository.all$().pipe(
    map((subCategories: ISubCategories[]) => {
      return subCategories.filter(subCategory => subCategory.parentCategoryId === this.category().id);
    }),
    switchMap((subCategories: ISubCategories[]) => {
      return this.productsRepository.all$().pipe(
        map((products: IProducts[]) => subCategories.map((subCategory: ISubCategories) => {
          return {
            ...subCategory,
            categoryName: subCategory.categoryName.trim(),
            products: products.filter(product => {
              if (Array.isArray(product.parentSubCategoryId)) {
                return product.parentSubCategoryId.indexOf(subCategory.id) >= 0;
              }

              return product.parentSubCategoryId === subCategory.id;
            }),
          };
        })),
      );
    })
  ));

  constructor(
    private readonly element: ElementRef,
    private readonly subCategoriesRepository: SubCategoriesRepository,
    private readonly productsRepository: ProductsRepository,
  ) { }

  @HostListener('scroll', ['$event'])
  public onScroll(): void {
    if (this.element.nativeElement.scrollTop >= 28) {
      this.headerSticky.set(true);
    } else if (this.element.nativeElement.scrollTop <= 15) {
      this.headerSticky.set(false);
    }
  }

}
