import { mainPageAnimation } from './main-page.animation';
import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BackgroundPlayerComponent, HappyHoursService } from '@core';
import { ConfettiComponent } from '@app/components/confetti/confetti.component';
import { TabBarComponent } from '@app/components/tab-bar/tab-bar.component';
import { NewMenuSyncService } from '@app/services/new-menu-sync.service';

@Component({
  selector: 'app-main',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [
    mainPageAnimation,
  ],
  imports: [
    ConfettiComponent,
    RouterOutlet,
    TabBarComponent,
    BackgroundPlayerComponent,
  ]
})
export class MainPageComponent implements OnInit {

  public confetti = false;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly changeRef: ChangeDetectorRef,
    private readonly happyHoursService: HappyHoursService,
    private readonly syncMenuService: NewMenuSyncService
  ) {}

  ngOnInit(): void {
    this.changeRef.detectChanges();

    this.initWorkerConfetti();
    this.syncMenuService.init();
  }

  public prepareRoute(outlet: RouterOutlet): unknown {
    return outlet?.activatedRouteData['animation'];
  }

  private initWorkerConfetti(): void {
    this.happyHoursService.isNow$.pipe(
      tap((yes) => this.confetti = yes),
      switchMap(() => timer(4500)),
      tap(() => this.confetti = false),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }
}
