@let withImage = !hesImagError() && product().sku !== 0 && productImage();
@let calories = product().calories;

<button
  class="header"
  type="button"
  (click)="close.emit()"
  coreUserActivityClickTracking="CloseProduct"
>
  <div class="back-navigation">
    <img src="/images/back.svg" alt="">
  </div>
</button>

@if (withImage) {
  <div class="img" [class.loading]="loading()">
    @if (loading()) {
      <app-loading/>
    }

    <img [src]="productImage()" alt="" (load)="onLoad()" (error)="onImageError($event)">
  </div>
}

<div class="shadow" [class.no_image]="!withImage">
  <div class="content">
    <div class="main_info">
      <div class="name">{{ product().name }}</div>
      <div class="price">{{ product().productPrice | number:'1.2-3' }}</div>
    </div>

    @if (calories && calories.length > 0) {
      <div class="calories">
        {{ calories }}
      </div>
    }

    <div class="description">
      {{ product().description }}
    </div>

    @if (allergens() && nutrition()) {
      <div class="accordion" [class.open]="accordion()">
        <button class="accordion__action" (click)="toggleAccordion()">
          Nutrition & Allergen Information
          <svg class="arrow" width="19" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 1 9.89 8.61a.55.55 0 0 1-.78 0L1.5 1" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round"/>
          </svg>
        </button>

        <div class="accordion__content">
          @if (allergens(); as allergens) {
            <div class="allergens">
              <div class="allergens__label">
                <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#izaupodkffxhxsv)">
                    <rect width="28" height="28" rx="14" fill="#fff"/>
                    <path
                      d="M11.105 11.578a6.558 6.558 0 0 0-1.449-.4c.068.421.188.94.4 1.449.58 1.398 1.549 2.078 2.96 2.078h.167v-.167c0-1.411-.68-2.38-2.078-2.96ZM14.817 18.914v.167h.167c2.04.063 3.083-1.66 3.359-3.526-.42.068-.939.188-1.448.4-1.398.58-2.078 1.548-2.078 2.959ZM11.105 15.954a6.558 6.558 0 0 0-1.449-.4c.068.421.188.94.4 1.449.58 1.398 1.549 2.078 2.96 2.078h.167v-.167c0-1.411-.68-2.38-2.078-2.96ZM14.742 8.06A6.56 6.56 0 0 0 14 6.754c-1.12 1.508-1.608 3.47-.118 4.869l.118.118.118-.118c.998-.998 1.202-2.163.624-3.563ZM14.817 14.538v.167h.167c1.411 0 2.38-.68 2.96-2.078.211-.51.331-1.028.4-1.448-.421.068-.94.188-1.449.4-1.398.58-2.078 1.548-2.078 2.959Z"
                      fill="#AF0E14"/>
                    <path
                      d="m24.418 4.738-3.275 3.275a.817.817 0 1 1-1.156-1.156l3.275-3.275C9.825-7.623-7.618 9.84 3.582 23.262l3.275-3.275a.817.817 0 1 1 1.156 1.156l-3.275 3.275c13.437 11.205 30.88-6.258 19.68-19.68Zm-5.169 9.094c.452 0 .818.366.818.817a8.65 8.65 0 0 1-.594 2.932c-.13.32-1.139 3.135-4.489 3.135h-.167v1.811c-.04 1.083-1.594 1.082-1.634 0v-1.811h-.167c-3.35 0-4.36-2.818-4.49-3.135a8.65 8.65 0 0 1-.593-2.932c0-.48.41-.852.888-.816a6.4 6.4 0 0 1-.294-.628 8.65 8.65 0 0 1-.594-2.933c0-.451.366-.817.818-.817.055 0 1.26.006 2.573.459-.072-.807.077-1.657.444-2.526a8.652 8.652 0 0 1 1.654-2.493.817.817 0 0 1 1.156 0 8.651 8.651 0 0 1 1.654 2.493c.367.87.516 1.719.444 2.526a8.73 8.73 0 0 1 2.573-.459c.452 0 .818.366.818.817a8.65 8.65 0 0 1-.594 2.933c-.09.22-.188.43-.295.628l.071-.001Z"
                      fill="#AF0E14"/>
                  </g>
                  <defs>
                    <clipPath id="izaupodkffxhxsv">
                      <rect width="28" height="28" rx="14" fill="#fff"/>
                    </clipPath>
                  </defs>
                </svg>
                allergens:
              </div>
              <div class="allergens__text">
                {{ allergens }}.
              </div>
            </div>
          }

          @if (nutrition(); as nutrition) {
            <div class="nutrition">
              @if (nutrition.servingSize) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Protein</div>
                  <div class="nutrition__item__value">{{ nutrition.servingSize }} g</div>
                </div>
              }
              @if (nutrition.calories) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Calories</div>
                  <div class="nutrition__item__value">{{ nutrition.calories }} Cals</div>
                </div>
              }
              @if (nutrition.cholesterol) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Cholesterol</div>
                  <div class="nutrition__item__value">{{ nutrition.cholesterol }} mg</div>
                </div>
              }
              @if (nutrition.fat) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Fat</div>
                  <div class="nutrition__item__value">{{ nutrition.fat }} g</div>
                </div>
              }
              @if (nutrition.transFat) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Trans Fat</div>
                  <div class="nutrition__item__value">{{ nutrition.transFat }} g</div>
                </div>
              }
              @if (nutrition.saturatedFat) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Saturated Fat</div>
                  <div class="nutrition__item__value">{{ nutrition.saturatedFat }} g</div>
                </div>
              }
              @if (nutrition.sodium) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Sodium</div>
                  <div class="nutrition__item__value">{{ nutrition.sodium }} mg</div>
                </div>
              }
              @if (nutrition.carbohydrates) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Carbohydrates</div>
                  <div class="nutrition__item__value">{{ nutrition.carbohydrates }} g</div>
                </div>
              }
              @if (nutrition.fiber) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Fiber</div>
                  <div class="nutrition__item__value">{{ nutrition.fiber }} g</div>
                </div>
              }
              @if (nutrition.sugar) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Sugar</div>
                  <div class="nutrition__item__value">{{ nutrition.sugar }} g</div>
                </div>
              }
              @if (nutrition.protein) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Protein</div>
                  <div class="nutrition__item__value">{{ nutrition.protein }} g</div>
                </div>
              }
              @if (nutrition.calcium) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Calcium</div>
                  <div class="nutrition__item__value">{{ nutrition.calcium }} mg</div>
                </div>
              }
              @if (nutrition.potassium) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Potassium</div>
                  <div class="nutrition__item__value">{{ nutrition.potassium }} mg</div>
                </div>
              }
              @if (nutrition.iron) {
                <div class="nutrition__item">
                  <div class="nutrition__item__name">Iron</div>
                  <div class="nutrition__item__value">{{ nutrition.iron }} mg</div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
