import { Component, DestroyRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import {
  ActivitySectionDirective,
  FileCacheModel,
  fromArray,
  IIntegrationKelseysMenuGlobalCategory,
  NetworkService,
  UserActivityClickTrackingDirective,
} from '@core';
import { LoadingComponent } from '@app/components/loading/loading.component';
import { MenuCategoryComponent } from '@app/components/menu-category/menu-category.component';
import { IMenuCategory, IProducts } from '@app/models';
import { NewMenuApiService } from '@app/services/new-menu-api.service';
import { NewMenuSyncService } from '@app/services/new-menu-sync.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FileCacheRepository } from '@core/lib/repositories/file-cache.repository';
import { CategoriesRepository } from '@app/repositories/categories.repository';
import { ProductDetailsComponent } from '@app/components/product-details/product-details.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-menus-tab',
  templateUrl: './menu-tab.component.html',
  imports: [
    LoadingComponent,
    MenuCategoryComponent,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
    ProductDetailsComponent,
  ],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 })),
      ]),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('400ms ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('400ms ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
  styleUrls: ['./menu-tab.component.scss'],
})

export class MenuTabComponent implements OnInit {

  public categories: IMenuCategory[] | [] = [];
  public currentCategory: IMenuCategory | null = null;
  public currentProduct: IProducts | null = null;
  public gCatId: number | null = null;

  public gCategories = toSignal(this.menuApi.globalCategories$, {
    requireSync: true,
  });

  public isOnline = toSignal(this.networkConnection.status$, {
    requireSync: true,
  });
  public isLoading = toSignal(this.syncService.isLoading$, {
    initialValue: true,
  })

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly filesCacheRepository: FileCacheRepository,
    private readonly networkConnection: NetworkService,
    private readonly domSanitizer: DomSanitizer,
    private readonly menuApi: NewMenuApiService,
    private readonly syncService: NewMenuSyncService,
    private readonly categoryRepository: CategoriesRepository,
  ) {
    this.menuApi.globalCategories$.pipe(
      distinctUntilChanged(),
      filter((menuGCategory: IIntegrationKelseysMenuGlobalCategory[]) => menuGCategory && menuGCategory.length > 0),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((gCategory) => {
      this.gCatId = gCategory.length > 0 ? gCategory[0].hierarchyId : null;
    });
  }

  public ngOnInit(): void {
    this.syncService.isLoading$.pipe(
      filter((loading: boolean) => !loading),
      take(1),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      if (this.gCatId) {
        this.setActive(this.gCatId);
      }
    });
  }

  public isActiveSection(hierarchyId: number): boolean {
    return this.gCatId === hierarchyId;
  }

  public setActive(hierarchyId: number): void {
    this.currentCategory = null;
    this.currentProduct = null;
    this.categories = [];
    this.gCatId = hierarchyId;
    this.categoryRepository.all$().pipe(
      map((categories: IMenuCategory[]) => {
        return categories.filter(category => category.hierarchyId === hierarchyId);
      }),
      switchMap((categories: IMenuCategory[]) => {
        return fromArray(categories).pipe(
          mergeMap((category) => {
            if (typeof category.bgSku === 'string' && category.hierarchyId !== 735) {
              return this.filesCacheRepository.one$(category.bgSku).pipe(
                take(1),
                map(file => {
                  if (file) {
                    const img = new FileCacheModel(file)

                    return img ? this.domSanitizer.bypassSecurityTrustUrl(img.objectUrl)
                      : undefined;
                  }
                  return undefined;
                }),
                map(file => (
                  { ...category, sanitizedUrl: file }
                )),
              );
            }
            return of(category);
          }),
          toArray(),
        );
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(category => {
      if (category.length === 1) {
        this.currentCategory = category[0];
      }
      else {
        this.categories = category;
      }
    });
  }

  public getDefaultBg(category: IMenuCategory): string {
    const categoryNameSanitized = category.categoryName.toLowerCase().trim();

    if (categoryNameSanitized === 'beer') {
      return '/images/menu/beer.jpg';
    }

    if (categoryNameSanitized === 'booze free bevvies') {
      return '/images/menu/booze-free-bevvies.jpg';
    }

    if (categoryNameSanitized === 'roadhouse cocktails') {
      return '/images/menu/roadhouse-cocktails.jpg';
    }

    if (categoryNameSanitized === 'roadhouse cocktails') {
      return '/images/menu/roadhouse-cocktails.jpg';
    }

    if (categoryNameSanitized === 'very best vinos') {
      return '/images/menu/very-best-vinos.jpg';
    }

    if (categoryNameSanitized === 'seltzers & ciders') {
      return '/images/menu/seltzers-ciders.jpg';
    }

    const foundCategory = this.gCategories().find(cat => cat.hierarchyId === category.hierarchyId);
    return foundCategory ? foundCategory.defaultCategoryBgImage ?? '' : '';
  }

}
