<div class="content" coreActivitySection="Specials">
  @if (loading()) {
    <app-loading/>
  } @else {
    @if (specials()) {
      <swiper-container
        class="swiper"
        autoplay="true"
        autoplay-delay="5000"
        pagination="true"
        direction="vertical"
        loop="true"
      >
        @for (slide of specials(); track slide.id; let i = $index) {
          <swiper-slide>
            <img class="slide" [src]="slide.contentLocal" alt="Image {{ i + 1 }}" />
          </swiper-slide>
        }
      </swiper-container>
    } @else {
      <div class="empty">
        {{ 'promo.empty' | translate }}
      </div>
    }
  }
</div>

