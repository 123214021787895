<button
  class="header"
  type="button"
  (click)="back.emit()"
  [coreUserActivityClickTracking]="data().sectionName.split(' ').join('') + 'Close'"
>
  <div class="back-navigation">
    <img src="/images/back.svg" alt="">
  </div>
  <div class="category-name">{{ data().sectionName }}</div>
</button>

@if (data().bottomImgSrc) {
  <div class="content">
    <div class="middle">
      @if (data().title || data().topImgSrc) {
        <div class="middle__text">
          @if (data().topImgSrc) {
            <img class="middle__text__img" [src]="data().topImgSrc" alt="">
          }
          <div class="middle__text__title"
               [ngStyle]="{'padding-bottom.px': data().description ? 24 : 0}">{{ data().title }}
          </div>
          @if (data().description) {
            <div class="middle__text__description" [innerHtml]="data().description"></div>
          }
        </div>
      }
      <div class="middle__qrBox" [class.shadow]="data().topImgSrc">
        <div class="middle__qrBox__qr">
          @for (qrSrc of data().qrImgSrc; track qrSrc) {
            <img [src]="qrSrc" alt="">
          }
        </div>
        @if (data().qrInfo) {
          <div class="middle__qrBox__qrInfo">
            {{ data().qrInfo }}
          </div>
        }
      </div>
    </div>
    <img class="bottom" [src]="data().bottomImgSrc" alt="">
  </div>
} @else {
  <div class="social">
    @if (data().topImgSrc) {
      <img class="social__img" [src]="data().topImgSrc" alt="">
    }
    @if (data().description) {
      <div class="social__description" [innerHtml]="data().description"></div>
    }
    <div class="social__qrBox">
      <div class="item">
        <img class="qr" [src]="data().qrImgSrc[0]" alt="">
        <div class="info">FOLLOW ON FB</div>
      </div>
      <div class="item">
        <img class="qr" [src]="data().qrImgSrc[1]" alt="">
        <div class="info">FOLLOW ON IG</div>
      </div>
    </div>
  </div>
}

<ng-template #social>
  <div class="social">
    @if (data().topImgSrc) {
      <img class="social__img" [src]="data().topImgSrc" alt="">
    }
    @if (data().description) {
      <div class="social__description" [innerHtml]="data().description"></div>
    }
    <div class="social__qrBox">
      <div class="item">
        <img class="qr" [src]="data().qrImgSrc[0]" alt="">
        <div class="info">FOLLOW ON FB</div>
      </div>
      <div class="item">
        <img class="qr" [src]="data().qrImgSrc[1]" alt="">
        <div class="info">FOLLOW ON IG</div>
      </div>
    </div>
  </div>
</ng-template>
