import { Component, DestroyRef } from '@angular/core';
import { RootComponent } from '@core/lib/root.component';
import { EMPTY, switchMap, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CallWaiterService } from '@core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    RootComponent,
  ]
})
export class AppComponent {

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly callWaiter: CallWaiterService,
  ) {
    this.callWaiter.mainStatus$.pipe(
      switchMap((callWaiter) => {
        if (!callWaiter) {
          return EMPTY;
        }

        return timer(240_000); // 4 minutes
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.callWaiter.setMain(false);
    });
  }

}
