import { DestroyRef, Injectable } from '@angular/core';
import {
  IMenuCategory,
  IMenuCategoryRaw,
  IProcessCategory,
  IProcessSubCategories,
  IProducts,
  IProductsRaw,
  ISubCategories,
  ISubCategoriesRaw,
  IUpsellItemRaw,
} from '../models';
import { BehaviorSubject, EMPTY, forkJoin, from, merge, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  repeatWhen,
  retryWhen,
  switchMap,
  take,
  tap,
  toArray,
} from 'rxjs/operators';
import { NewMenuApiService } from './new-menu-api.service';
import {
  arrayDiff,
  arrayDiffIdCompare,
  FileCacheService,
  fromArray,
  IIntegrationKelseysMenuGlobalCategory,
  NetworkService,
  WatchdogService,
} from '@core';
import { FileCacheRepository } from '@core/lib/repositories/file-cache.repository';
import { CategoriesRepository } from '@app/repositories/categories.repository';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SubCategoriesRepository } from '@app/repositories/subCategories.repository';
import { ProductsRepository } from '@app/repositories/products.repository';

interface ToUpdate<T> {
  origin: T;
  value: T;
}

@Injectable({
  providedIn: 'root',
})
export class NewMenuSyncService {

  private readonly logger = this.watchdog.tag('Sync menu', 'yellow');
  public menuGCategory: IIntegrationKelseysMenuGlobalCategory[] = [];
  public isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly filesCacheRepository: FileCacheRepository,
    private readonly watchdog: WatchdogService,
    private readonly network: NetworkService,
    private readonly menuApi: NewMenuApiService,
    private readonly filesCache: FileCacheService,
    private readonly categoryRepository: CategoriesRepository,
    private readonly subCategoriesRepository: SubCategoriesRepository,
    private readonly productsRepository: ProductsRepository,
  ) {}

  public init(): void {
    this.menuApi.globalCategories$.pipe(
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((gCategory) => {
      this.menuGCategory = gCategory;
      this.initMenu();
    });
  }

  public initMenu(): void {
    this.logger.log('Initialize Menu sync')

    if (this.menuGCategory && this.menuGCategory.length > 0) {
      this.updatePeriodical(10 * 60 * 1000).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe();
    }
    else {
      this.logger.info('MenuGCategory is empty, skipping init');
      this.isLoading$.next(false);
    }
  }

  public updatePeriodical(period: number): Observable<void> {
    const repeatDelay = () => merge(
      this.network.status$.pipe(
        distinctUntilChanged(),
        filter((s) => s),
      ),
      this.menuApi.globalCategories$.pipe(
        distinctUntilChanged(),
        filter((categories) => categories.length > 0),
      ),
    ).pipe(
      tap(() => this.logger.info(`Sync delay until ${ new Date(Date.now() + period).toLocaleString() }`)),
      delay(period),
      tap(() => this.logger.info('Sync restart')),
    );

    return this.updateMenuData().pipe(
      repeatWhen((completed) => completed.pipe(switchMap(repeatDelay))),
      retryWhen((errors) => errors.pipe(switchMap(repeatDelay))),
    );
  }

  public sync(allCategories: { hierarchyId: number, categories: IMenuCategoryRaw[] }[]): Observable<IProducts[]> {
    this.isLoading$.next(true);
    return from(allCategories).pipe(
      mergeMap(({ hierarchyId, categories }) => from(categories).pipe(
        map(category => this.processCategory(category, hierarchyId)),
      )),
      toArray(),
      switchMap((processCategories: IProcessCategory[]) => this.syncCategories(processCategories)),
      switchMap((processSubCategories: IProcessSubCategories[]) => this.syncSubCategories(processSubCategories)),
      switchMap((products) => this.syncProducts(products)),
      tap(() => {
        this.isLoading$.next(false);
        this.logger.log('Sync completed')
      }),
    );
  }

  public updateMenuData(): Observable<void> {
    return forkJoin(
      this.menuGCategory.map(gCategory => {
        return this.menuApi.getCategory(gCategory.hierarchyId).pipe(
          map((categories: IMenuCategoryRaw[]) => (
            { hierarchyId: gCategory.hierarchyId, categories }
          )),
          catchError(() => {
            return of({ hierarchyId: gCategory.hierarchyId, categories: [] });
          }),
        );
      }),
    ).pipe(
      switchMap(cats => this.sync(cats)),
      map(() => void 0),
      catchError(error => {
        this.logger.error('Error during sync', error);
        return of(void 0);
      }),
    );
  }

  private processUpsellItem(upsellItem: IUpsellItemRaw): number {
    return upsellItem.id;
  }

  private processProducts(products: IProductsRaw[], hierarchyId: number, subCategoryId: number): IProducts[] {
    return products.map((product: IProductsRaw) => {
      const volume = product.description.split('|').filter(v => /^\s*\d+oz\s*$/.test(v)).map(v => v.trim());
      const currentProduct: IProducts = {
        id: product.productID,
        name: product.name,
        showImage: product.showImage,
        productPrice: product.productPrice,
        isVegetarian: product.isVegetarian,
        description: product.description,
        calories: product.calories,
        allergenList: product.allergenList?.split(','),
        upgrades: product.upgrades ? product.upgrades.map((upgrade: IProductsRaw) => upgrade.productID) : [],
        upsellItem: product.upsellItem ? this.processUpsellItem(product.upsellItem) : undefined,
        customFields: product.customFields ? product.customFields : undefined,
        sku: 0,
        hierarchyId,
        parentSubCategoryId: subCategoryId,
      };

      if (volume.length > 0) {
        currentProduct.volume = volume;
      }

      if (hierarchyId !== 723 && hierarchyId !== 735) {
        currentProduct.sku = this.menuApi.getImgUrl(product.sku);
      }

      return currentProduct;
    });
  }

  private processSubCategory(subCategories: ISubCategoriesRaw[], hierarchyId: number, categoryId: number):
    {
      subCategory: ISubCategories,
      products: IProducts[]
    }[] {
    return subCategories.map((subCategory: ISubCategoriesRaw) => {
      const currentSubCategory: ISubCategories = {
        id: subCategory.categoryID,
        categoryName: subCategory.categoryName,
        parentCategoryId: categoryId,
      };

      const products = this.processProducts(subCategory.products, hierarchyId, subCategory.categoryID);

      return { subCategory: currentSubCategory, products };
    });
  }

  public processCategory(category: IMenuCategoryRaw, hierarchyId: number):
    {
      category: IMenuCategory,
      subCategories: {
        subCategory: ISubCategories,
        products: IProducts[]
      }[]
    } {
    const subCategories = this.processSubCategory(category.subCategories, hierarchyId, category.categoryID);
    const bgSku = category.subCategories[0].products[0].sku;

    const currentCategory: IMenuCategory = {
      id: category.categoryID,
      categoryName: category.categoryName,
      bgSku: hierarchyId !== 723 ? this.menuApi.getImgUrl(bgSku) : 0,
      hierarchyId,
    };

    return { category: currentCategory, subCategories };
  }

  private toAddProducts(products: IProducts[]): Observable<IProducts[]> {
    return from(products.filter(
      (product) => product.name !== 'Desc',
    )).pipe(
      mergeMap((product) => this.productsRepository.add$(product)),
      mergeMap((trueProduct) =>
        typeof trueProduct.sku !== 'string'
          ? of(trueProduct)
          : this.filesCache.downloadFile(trueProduct.sku).pipe(
            map(() => trueProduct),
          ),
      ),
      toArray(),
      tap((products) => this.logger.info('Product added successfully', products)),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  public toUpdateProducts(products: ToUpdate<IProducts>[]): Observable<IProducts[]> {
    return fromArray(products).pipe(
      mergeMap(({ origin, value }) => this.productsRepository.update$(value).pipe(
        map((product) => (
          {
            origin,
            product,
          }
        )),
      )),
      mergeMap(({ origin, product }) => {
        if (!product) {
          return EMPTY;
        }

        if (product.sku === origin.sku || (
          typeof product.sku !== 'string' && typeof origin.sku !== 'string'
        )) {
          return of(product);
        }

        if (typeof product.sku !== 'string' && typeof origin.sku === 'string') {
          return this.filesCacheRepository.delete$(origin.sku).pipe(
            map(() => product),
          );
        }

        if (typeof product.sku === 'string' && typeof origin.sku !== 'string') {
          return this.filesCache.downloadFile(product.sku).pipe(
            map(() => product),
          );
        }

        if (typeof product.sku === 'string' && typeof origin.sku === 'string') {
          return this.filesCacheRepository.delete$(origin.sku).pipe(
            mergeMap(() => this.filesCache.downloadFile(product.sku as string)),
            map(() => product),
          );
        }

        return of(product);
      }),
      toArray(),
    );
  }

  private toDeleteProducts(products: IProducts[]): Observable<IProducts[]> {
    return from(products).pipe(
      mergeMap((product) =>
        this.productsRepository.delete$(product.id).pipe(
          filter((deleted) => deleted),
          mergeMap(() =>
            typeof product.sku !== 'string'
              ? of(product)
              : this.filesCacheRepository.delete$(product.sku).pipe(
                map(() => product),
              ),
          ),
        ),
      ),
      toArray(),
      tap((products) => this.logger.info('Product deleted successfully', products)),
    );
  }

  private toAddSubCategories(subCategories: ISubCategories[]): Observable<ISubCategories[]> {
    return fromArray(subCategories).pipe(
      mergeMap((subCategory) => this.subCategoriesRepository.add$(subCategory)),
      toArray(),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private toUpdateSubCategories(subCategories: ToUpdate<ISubCategories>[]): Observable<ISubCategories[]> {
    return fromArray(subCategories).pipe(
      switchMap(({ value }) => this.subCategoriesRepository.update$(value)),
      toArray(),
      tap(() => this.logger.info('SubCategories is updated', subCategories)),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private toDeleteSubCategories(subCategories: ISubCategories[]): Observable<ISubCategories[]> {
    return from(subCategories).pipe(
      mergeMap((subCategory) => this.subCategoriesRepository.delete$(subCategory.id)),
      filter((deleted) => deleted),
      toArray(),
      map(() => subCategories),
      tap(() => this.logger.info('SubCategories is deleted', subCategories)),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private toAddCategory(categories: IMenuCategory[]): Observable<IMenuCategory[]> {
    this.logger.info('Category to add', categories);
    return from(categories).pipe(
      mergeMap((category) => this.categoryRepository.add$(category)),
      mergeMap((trueCategory) =>
        typeof trueCategory.bgSku !== 'string'
          ? of(trueCategory)
          : this.filesCache.downloadFile(trueCategory.bgSku).pipe(
            map(() => trueCategory),
          ),
      ),
      toArray(),
      tap((category) => this.logger.info('Category added', category)),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private toUpdateCategory(categories: ToUpdate<IMenuCategory>[]): Observable<IMenuCategory[]> {
    this.logger.info('Category to update', categories);
    return fromArray(categories).pipe(
      mergeMap(({ origin, value }) =>
        this.categoryRepository.update$(value).pipe(
          map((category) => (
            {
              origin,
              category,
            }
          )),
        )
      ),
      mergeMap(({ origin, category }) => {
        if (!category) {
          return EMPTY;
        }

        if (category.bgSku === origin.bgSku || (
          typeof category.bgSku !== 'string' && typeof origin.bgSku !== 'string'
        )) {
          return of(category);
        }

        if (typeof category.bgSku !== 'string' && typeof origin.bgSku === 'string') {
          return this.filesCache.delete(origin.bgSku).pipe(
            map(() => category),
          );
        }

        if (typeof category.bgSku === 'string' && typeof origin.bgSku !== 'string') {
          return this.filesCache.downloadFile(category.bgSku).pipe(
            map(() => category),
          );
        }

        if (typeof category.bgSku === 'string' && typeof origin.bgSku === 'string') {
          return this.filesCache.delete(origin.bgSku).pipe(
            mergeMap(() => this.filesCache.downloadFile(category.bgSku as string)),
            map(() => category),
          );
        }

        return of(category);
      }),
      toArray(),
      takeUntilDestroyed(this.destroyRef),
      tap((category) => this.logger.info('Category updated', category)),
    );
  }

  private toDeleteCategory(categories: IMenuCategory[]): Observable<IMenuCategory[]> {
    this.logger.info('Category to delete', categories)
    return from(categories).pipe(
      mergeMap((category) =>
        this.categoryRepository.delete$(category.id).pipe(
          filter((del) => del && category.bgSku === 'string'),
          mergeMap(() =>
            typeof category.bgSku !== 'string'
              ? of(category)
              : this.filesCacheRepository.delete$(category.bgSku).pipe(
                take(1),
                map(() => category),
              ),
          ),
        ),
      ),
      toArray(),
      tap(() => this.logger.info('Category deleted', categories)),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private syncProducts(products: IProducts[]): Observable<IProducts[]> {
    return this.productsRepository.all$().pipe(
      take(1),
      mergeMap((currentProducts) => {
        const add = arrayDiff(products, currentProducts, arrayDiffIdCompare);
        const del = arrayDiff(currentProducts, products, arrayDiffIdCompare);
        const upd = currentProducts.reduce<ToUpdate<IProducts>[]>((acc, origin) => {
          const value = products.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }
          return acc;
        }, []);

        return forkJoin([
          this.toAddProducts(add).pipe(catchError(error => {
            console.error('Error adding products:', error);
            return of([]);
          })),
          this.toUpdateProducts(upd).pipe(catchError(error => {
            console.error('Error updating products:', error);
            return of([]);
          })),
          this.toDeleteProducts(del).pipe(catchError(error => {
            console.error('Error deleting products:', error);
            return of([]);
          })),
        ]).pipe(
          map(() => products),
        );
      }),
      tap(() => console.log('Product after sync =====>')),
    );
  }

  private syncSubCategories(processSubCategories: IProcessSubCategories[]): Observable<IProducts[]> {
    const newSubcategories = processSubCategories.reduce<ISubCategories[]>((acc, item) => {
      return acc.concat(item.subCategory);
    }, []);

    return this.subCategoriesRepository.all$().pipe(
      take(1),
      mergeMap((currentSubcategories) => {
        const add = arrayDiff(newSubcategories, currentSubcategories, arrayDiffIdCompare);
        const del = arrayDiff(currentSubcategories, newSubcategories, arrayDiffIdCompare);
        const upd = currentSubcategories.reduce<ToUpdate<ISubCategories>[]>((acc, origin) => {
          const value = newSubcategories.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }
          return acc;
        }, []);

        return forkJoin([
          this.toAddSubCategories(add).pipe(catchError(error => {
            console.error('Error adding subcategories:', error);
            return of([]);
          })),
          this.toUpdateSubCategories(upd).pipe(catchError(error => {
            console.error('Error updating subcategories:', error);
            return of([]);
          })),
          this.toDeleteSubCategories(del).pipe(catchError(error => {
            console.error('Error deleting subcategories:', error);
            return of([]);
          })),
        ]).pipe(
          map(() => {
            const productMap = processSubCategories.reduce<Record<number, IProducts>>((acc, subCategory) => {
              subCategory.products.forEach((product) => {
                const existingProduct = acc[product.id];
                if (existingProduct) {
                  if (Array.isArray(existingProduct.parentSubCategoryId)) {
                    existingProduct.parentSubCategoryId.push(product.parentSubCategoryId as number);
                  }
                  else {
                    existingProduct.parentSubCategoryId = [
                      existingProduct.parentSubCategoryId,
                      product.parentSubCategoryId as number,
                    ];
                  }
                }
                else {
                  acc[product.id] = {
                    ...product,
                  };
                }
              });
              return acc;
            }, {});

            return Object.values(productMap);
          }),
        );
      }),
      tap(() => console.log('Product after syncSubSync ++++++>')),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private syncCategories(processCategories: IProcessCategory[]): Observable<IProcessSubCategories[]> {
    return this.categoryRepository.all$().pipe(
      take(1),
      mergeMap((currentCategories) => {
        const newCategories = processCategories.map(processCategory => processCategory.category);
        const add = arrayDiff(newCategories, currentCategories, arrayDiffIdCompare);
        const del = arrayDiff(currentCategories, newCategories, arrayDiffIdCompare);
        const upd = currentCategories.reduce<ToUpdate<IMenuCategory>[]>((acc, origin) => {
          const value = newCategories.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }
          return acc;
        }, []);

        return forkJoin([
          this.toAddCategory(add).pipe(catchError(error => {
            this.logger.error('Error adding categories', error);
            return of([]);
          })),
          this.toUpdateCategory(upd).pipe(catchError(error => {
            this.logger.error('Error updating categories:', error);
            return of([]);
          })),
          this.toDeleteCategory(del).pipe(catchError(error => {
            this.logger.error('Error deleting categories:', error);
            return of([]);
          })),
        ]).pipe(
          map(() => processCategories.reduce<IProcessSubCategories[]>((acc, processCategory) => {
            return acc.concat(processCategory.subCategories);
          }, [])),
        );
      }),
      tap(() => this.logger.log('Category synchronized successfully')),
    );
  }
}
