import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivitySectionDirective,
  CallWaiterService,
  RestaurantTableService,
  UserActivityClickTrackingDirective,
} from '@core';
import { KelseysSectionsGridComponent } from '@app/components/kelseys-sections-grid/kelseys-sections-grid.component';

@Component({
  selector: 'app-service-centre',
  templateUrl: './service-centre.component.html',
  imports: [
    KelseysSectionsGridComponent,
    TranslateModule,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./service-centre.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCentreComponent {

  public readonly table = toSignal(this.restaurantTable.table$);

  public readonly withCallWaiterMain = toSignal(this.callWaiter.withMain$, {
    initialValue: false,
  });

  public readonly callWaiterMain = toSignal(this.callWaiter.mainStatus$, {
    initialValue: true,
  });

  constructor(
    private readonly restaurantTable: RestaurantTableService,
    private readonly callWaiter: CallWaiterService,
  ) {}

  public handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      $event.preventDefault();
      this.callWaiter.setMain(!this.callWaiterMain());
    }
  }

}
